<template>
  <div class="card card-custom card-stretch">
    <div class="card-header py-3">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("user_details.spot_history") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Dropdown-->
        <DatatableDropdown />
        <!--end::Dropdown-->
      </div>
    </div>

    <div class="card-body">
      <div class="text-center" v-if="isLoading">
        {{ $t("commons.data_loading") }}...
      </div>
      <table
        class="table table-striped table-bordered collapsed"
        style="width: 100%"
        ref="kt_datatable"
        v-else
      >
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t("currencies_management.symbol") }}</th>
            <th scope="col">{{ $t("user_details.direction.name") }}</th>
            <th scope="col">{{ $t("commons.amount") }}</th>
            <th scope="col">{{ $t("user_details.traded_amount") }}</th>
            <th scope="col">{{ $t("commons.price") }}</th>
            <th scope="col">{{ $t("user_details.transaction_time") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in spotHistory" :key="idx">
            <th class="font-weight-bolder">{{ item.id }}</th>
            <td>{{ item.symbol }}</td>
            <td>{{ getDirection(item.direction) }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.traded_amount }}</td>
            <td>{{ item.price }}</td>
            <td>{{ getTransactionTime(item.time) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import DatatableDropdown from "@/components/datatable/Dropdown.vue";
import $ from "jquery";

export default {
  name: "FinancialTransactionHistory",
  components: {
    DatatableDropdown,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      permissions: (state) => state.customer.permissions,
      isLoading: (state) => state.userdetails.isLoading,
      spotHistory: (state) => state.userdetails.spotHistory,
    }),
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(
        (val) => String(val.name) === String(permission)
      );
    },
    getDirection(direction) {
        return this.$te(`user_details.direction.${direction}`)
          ? this.$t(`user_details.direction.${direction}`)
          : "-";
    },
    getTransactionTime(value) {
      if (value === null || value === undefined || value === "-") return "-";
      if (
        typeof value === "number" ||
        (typeof value === "string" && !isNaN(value))
      )
        return new Date(+value * 1000).toLocaleString("en-US");
      return "-";
    },
  },
  created() {
    if (
      this.permissions?.length > 0 &&
      !this.checkPermission("crm.users.show.transactions")
    ) {
      this.$router.push("/no-permission");
      return;
    }
    const self = this;
    self.$store
      .dispatch("userdetails/fetchSpotHistoryAction", {
        id: this.$route.params.id,
      })
      .then(function () {
        self.$nextTick(function () {
          const fileName = "financial-transactions";
          $(self.$refs["kt_datatable"]).DataTable({
            responsive: true,
            dom: "Blfrtip",
            pagingType: "full_numbers",
            buttons: ["copy", "csv", "excel", "pdf", "print"].map((el) => ({
              extend: el,
              filename: fileName,
            })),
            initComplete: function () {
              $(".dt-buttons").hide();
            },
            columnDefs: [
              /* { orderable: false, targets: -1 }, */
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 },
            ],
            order: [[0, "desc"]],
            pageLength: 10,
            lengthMenu: [
              [10, 25, 50, -1],
              [10, 25, 50, "All"],
            ],
            language: {
              url: `/assets/languages/datatable/${this.lang}.json`,
            },
          });
          $("#printExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-print").trigger("click");
          });
          $("#copyExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-copy").trigger("click");
          });
          $("#excelExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-excel").trigger("click");
          });
          $("#csvExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-csv").trigger("click");
          });
          $("#pdfExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-pdf").trigger("click");
          });
        });
      });
  },
};
</script>
